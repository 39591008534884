import { PaidPlans } from '@wix/ambassador-checkout-server/types';
import { Member } from '@wix/ambassador-members-ng-api/types';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import settingsParams from '../../components/BookingsForm/settingsParams';
import {
  BusinessInfo,
  PaymentMethod,
  ReservedPaymentOptionIds,
  SettingsSubTab,
  SettingsTab,
  TFunction,
  Dialog,
  ServicePaymentDetails,
} from '../../types/types';
import {
  getActiveSchedule,
  getServiceType,
  mapCatalogServiceToService,
  Service,
} from '../mappers/service.mapper';
import { ServicePayment, ServiceType } from '@wix/bookings-uou-types';
import { GetActiveFeaturesResponse } from '@wix/ambassador-services-catalog-server/types';
import { SlotAvailability } from '@wix/ambassador-availability-calendar/types';
import { FormApi } from '../../api/FormApi';
import { IWidgetControllerConfig } from '@wix/native-components-infra/dist/src/types/types';
import {
  BookingsQueryParams,
  WixOOISDKAdapter,
} from '@wix/bookings-adapter-ooi-wix-sdk';
import { createDummyState } from '../dummies/dummy-data';
import {
  getFirstAvailablePaymentOptionId,
  isOfferedAsOneTimeOnly,
  isOfferedAsPricingPlanOnly,
} from '../payment/payment';
import { FormView, Submission } from '@wix/forms-ui/types';
import { getSessionValues } from '../storageFunctions';
import { FormErrors, EmptyStateErrorType } from '../../types/errors';
import { getErrorByType } from '../errors/errors';
import { CouponInfo } from '../../types/coupons';
import {
  BookingRequestKeyMappings,
  getFieldFromSchema,
} from '../mappers/form-submission.mapper';
import { mapServicePaymentDto } from '@wix/bookings-uou-mappers';

export type EditorContext = {
  isDummy: boolean;
  selectedSettingsTabId?: SettingsTab;
  selectedSettingsSubTabId?: SettingsSubTab;
};
export enum FormStatus {
  IDLE = 'idle',
  SSR = 'ssr',
  PROCESSING_BOOK_REQUEST = 'processing-book-request',
  PROCESSING_USER_DETAILS = 'processing-user-details',
  PROCESSING_PAYMENT_DETAILS = 'processing-payment-details',
  INITIALIZING = 'initializing',
  PROCESSING_COUPON = 'processing-coupon',
}

export type FormState = {
  service: Service;
  isPricingPlanInstalled: boolean;
  couponInfo: CouponInfo;
  businessInfo: BusinessInfo;
  activeFeatures: GetActiveFeaturesResponse;
  slotAvailability: SlotAvailability;
  pricingPlanDetails?: PaidPlans;
  memberDetails?: Member;
  errors: FormErrors[];
  selectedPaymentOptionId: string;
  editorContext: EditorContext;
  status: FormStatus;
  overrideDefaultFieldsValues?: boolean;
  dialog?: Dialog;
  paymentDetails: ServicePaymentDetails;
  formInputs: FormInputs;
};

export type FormInputs = {
  numberOfParticipants: number;
  email?: string;
};

export async function createInitialState({
  t,
  flowApi,
  wixSdkAdapter,
  formApi,
}: {
  t: TFunction;
  flowApi: ControllerFlowAPI;
  wixSdkAdapter: WixOOISDKAdapter;
  formApi: FormApi;
}): Promise<FormState> {
  const { settings, controllerConfig } = flowApi;

  if (wixSdkAdapter.isSSR()) {
    return {
      status: FormStatus.SSR,
    } as FormState;
  }

  const serviceId = getSessionValues(
    wixSdkAdapter,
    BookingsQueryParams.SERVICE_ID,
  );

  if (
    wixSdkAdapter.isEditorMode() ||
    (wixSdkAdapter.isPreviewMode() && !serviceId)
  ) {
    const [catalogData, isPricingPlanInstalled] = await Promise.all([
      formApi.getCatalogData(),
      wixSdkAdapter.isPricingPlanInstalled().catch(() => false),
    ]);
    return createDummyState(
      flowApi,
      catalogData.businessInfo,
      isPricingPlanInstalled,
    );
  }

  try {
    const {
      areCouponsAvailable,
      catalogData,
      listSlots,
      memberDetails,
      slotAvailability,
      pricingPlanDetails,
      isPricingPlanInstalled,
      errors,
    } = await fetchInitialData({
      formApi,
      controllerConfig,
      wixSdkAdapter,
      serviceId,
    });

    const emptyStateError = getErrorByType({
      errorType: EmptyStateErrorType,
      errors,
    });

    if (emptyStateError) {
      throw emptyStateError;
    }

    const preFilledValues: Maybe<Submission> = getSessionValues(
      wixSdkAdapter,
      BookingsQueryParams.FILLED_FIELDS,
    );
    const service = mapCatalogServiceToService({
      catalogData: catalogData!,
      slotAvailability: slotAvailability!,
      pricingPlanDetails,
      preFilledValues,
      t,
      listSlots,
      serviceId,
      isNameFieldDeprecationEnabled: flowApi.experiments.enabled(
        'specs.bookings.UoUFormNameDeprecation',
      ),
    });

    const defaultPaymentOptionId = getDefaultPaymentOptionId(
      settings,
      service.payment,
      pricingPlanDetails,
    );
    const numberOfParticipants = getNumberOfParticipants(
      preFilledValues,
      service.formSchema,
    );
    const email = getEmailValue(preFilledValues, service.formSchema);
    const paymentDetails = mapServicePaymentDto(
      catalogData.service,
    ) as ServicePaymentDetails;
    paymentDetails.totalPrice = paymentDetails.price;

    const selectedPaymentOptionId = getFirstAvailablePaymentOptionId({
      service,
      pricingPlanDetails: pricingPlanDetails!,
      isPricingPlanInstalled: isPricingPlanInstalled!,
      businessInfo: catalogData!.businessInfo,
      selectedPaymentOptionId: defaultPaymentOptionId,
      t,
      settings,
      numberOfParticipants,
    })!;

    return {
      activeFeatures: catalogData!.activeFeatures,
      service,
      businessInfo: catalogData!.businessInfo,
      slotAvailability: slotAvailability!,
      isPricingPlanInstalled: isPricingPlanInstalled!,
      pricingPlanDetails,
      memberDetails,
      errors,
      paymentDetails,
      selectedPaymentOptionId,
      couponInfo: {
        areCouponsAvailable,
        isCouponInputDisplayed: false,
      },
      editorContext: {
        isDummy: false,
      },
      status: FormStatus.INITIALIZING,
      overrideDefaultFieldsValues: false,
      dialog: undefined,
      formInputs: {
        email,
        numberOfParticipants,
      },
    };
  } catch (formError) {
    return {
      errors: [formError],
    } as FormState;
  }
}

export const getDefaultPaymentOptionId = (
  settings: ControllerFlowAPI['settings'],
  servicePayment: ServicePayment,
  pricingPlanDetails?: PaidPlans,
) => {
  const planId = pricingPlanDetails?.defaultPlan?.paidPlan?.orderId;
  if (planId) {
    return planId;
  }
  if (isOfferedAsOneTimeOnly(servicePayment)) {
    return ReservedPaymentOptionIds.SingleSession;
  }
  if (isOfferedAsPricingPlanOnly(servicePayment)) {
    return ReservedPaymentOptionIds.BuyAPricingPlan;
  }
  return settings.get(settingsParams.defaultPaymentMethod) ===
    PaymentMethod.SINGLE
    ? ReservedPaymentOptionIds.SingleSession
    : ReservedPaymentOptionIds.BuyAPricingPlan;
};

const fetchInitialData = async ({
  formApi,
  controllerConfig,
  wixSdkAdapter,
  serviceId,
}: {
  formApi: FormApi;
  controllerConfig: IWidgetControllerConfig;
  wixSdkAdapter: WixOOISDKAdapter;
  serviceId?: string;
}) => {
  let errors: FormErrors[] = [];
  if (!serviceId) {
    throw EmptyStateErrorType.INVALID_SERVICE_ID;
  }

  let slotAvailability = getSessionValues(
    wixSdkAdapter,
    BookingsQueryParams.AVAILABILITY_SLOT,
  );
  const resourceId = slotAvailability?.slot!.resource!.id!;
  const startTime = slotAvailability?.slot!.startDate!;
  const user = controllerConfig.wixCodeApi.user.currentUser;
  const isLoggedInUser = user.loggedIn;
  const shouldGetPricingPlanDetails = isLoggedInUser && startTime;

  const [
    areCouponsAvailable,
    catalogData,
    memberDetails,
    pricingPlanDetails,
    isPricingPlanInstalled,
  ] = await Promise.all([
    formApi.areCouponsAvailableForService(),
    formApi.getCatalogData({ serviceId, resourceId }),
    isLoggedInUser
      ? formApi.getMemberDetails(user.id).catch((e) => {
          errors = [...errors, e];
          return undefined;
        })
      : undefined,
    shouldGetPricingPlanDetails
      ? formApi
          .getPricingPlanDetails({
            serviceId,
            startTime,
          })
          .catch((e) => {
            errors = [...errors, e];
            return undefined;
          })
      : undefined,
    wixSdkAdapter.isPricingPlanInstalled().catch(() => false),
  ]);

  const activeSchedule = getActiveSchedule(catalogData?.service!);
  const scheduleId = activeSchedule?.id!;
  const firstSessionStart = activeSchedule?.firstSessionStart;
  const lastSessionEnd = activeSchedule?.lastSessionEnd;

  const type = getServiceType(activeSchedule);
  const isCourse = type === ServiceType.COURSE;

  if (isCourse && !firstSessionStart) {
    throw EmptyStateErrorType.COURSE_WITHOUT_SESSIONS;
  }

  const [listSlots, courseAvailability] = await Promise.all([
    isCourse && lastSessionEnd
      ? formApi.getSlots({
          firstSessionStart: firstSessionStart!,
          lastSessionEnd,
          scheduleId,
        })
      : {},
    isCourse ? formApi.getAvailability({ scheduleId }) : {},
  ]);

  if (isCourse && courseAvailability) {
    slotAvailability = {
      openSpots:
        Number(courseAvailability.capacity) -
        Number(courseAvailability.totalNumberOfParticipants),
      slot: {
        startDate: firstSessionStart,
        endDate: lastSessionEnd,
      },
    };
  }

  const isSlotAvailabilityValid = isCourse || slotAvailability?.slot;
  if (!isSlotAvailabilityValid) {
    throw EmptyStateErrorType.INVALID_SLOT_AVAILABILITY;
  }

  return {
    catalogData,
    listSlots,
    memberDetails,
    slotAvailability,
    pricingPlanDetails,
    isPricingPlanInstalled,
    errors,
    areCouponsAvailable,
  };
};

const getNumberOfParticipants = (
  preFilledValues: Submission | undefined,
  formSchema: FormView,
): number => {
  const numberOfParticipantsField = getFieldFromSchema(
    formSchema,
    BookingRequestKeyMappings.NO_OF_PARTICIPANTS,
  );
  const fieldId = numberOfParticipantsField?.externalId;
  const maxNumberOfParticipants =
    numberOfParticipantsField?.renderInfo?.displayProperties?.options?.length ||
    1;
  const participantsInStorage = preFilledValues?.[fieldId!] as string;
  const numberOfParticipants = participantsInStorage
    ? parseInt(participantsInStorage, 10) || 1
    : 1;
  return Math.min(numberOfParticipants, maxNumberOfParticipants);
};

const getEmailValue = (
  preFilledValues: Submission | undefined,
  formSchema: FormView,
): string => {
  const emailField = getFieldFromSchema(
    formSchema,
    BookingRequestKeyMappings.EMAIL,
  );
  const fieldId = emailField?.externalId;
  return (preFilledValues?.[fieldId!] as string) || '';
};
